<template>
  <div>
    <vs-card>
      <h3>Selecione o arquivo para importação de projetos</h3>

      <fieldset class="fieldErrors" v-if="errors.count() > 0">
        <legend>Atenção aos erros do formulário:</legend>
        <ul>
          <li v-for="(error, index) in errors.all()" :key="index" class="alert">
            {{ error }}
          </li>
        </ul>
      </fieldset>
      <vs-divider></vs-divider>
      <form>
        <p>* Arquivo do tipo xls ou xlsx</p>
        <input
          ref="image"
          type="file"
          name="importFile"
          @change="onInput"
          v-validate="'required|ext:xls,xlsx'"
          data-vv-as="Arquivo de importação"
        />
      </form>
      <vs-divider></vs-divider>

      <vs-button @click="submitFile()" class="mr-6">Processar</vs-button>
    </vs-card>
  </div>
</template>

    <script>
import { notifyError, notifySuccess } from "../utils/helpers";
import siriusAPI from "../services";
export default {
  data() {
    return {
      files: null,
    };
  },

  methods: {
    async onInput(event) {
      this.files = event.target.files[0];
    },
    submitFile() {
      this.$validator.validate().then((valid) => {
        if (!valid) {
          notifyError(this, "Corrigir os erros apresentados.");
          return;
        } else {
          this.$vs.loading();
          const formData = new FormData();
          formData.append("file", this.files, this.files.name);
          new siriusAPI()
            .import(formData)
            .then(() => {
              this.$vs.loading.close();
              this.files = null;
              notifySuccess(this, "Arquivo importado com sucesso");
            })
            .catch((error) => {
              this.$vs.loading.close();
              notifyError(
                this,
                "Erro ao tentar carregar a lista de projetos: " + error
              );
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.alert {
  color: #f00;
}
.bt {
  margin-right: 1em;
}
.duplicated {
  color: red;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
